import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";

const LinkedinAds = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Linkedin Ads",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/linkedin-ads/",
      }}
    >
      <section className="container-fluid linkedin_section">
        <div className="text-center">
          <h1>Linkedin Ads</h1>
        </div>
        <div className="row social_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              LinkedIn - największa sieć profesjonalistów i osób decyzyjnych na
              jednej platformie. Obecnie zrzesza ponad 500 mln użytkowników,
              którzy podają tam na swój temat wiele wartościowych informacji.
            </p>
            <p className="text-center">
              Żadna platforma społecznościowa nie daje tak precyzyjnego pod
              kątem biznesowym narzędzia reklamowego, jak LinkedIn. Szukasz
              właścicieli małych firm z branży meblarskiej? A może osób na
              stanowiskach managerskich w dużych korporacjach? Dotarcie do nich
              dzięki kampaniom LinkedIn to nie problem. Oprócz wyświetlenia
              użytkownikom reklam banerowych można także wysłać do nich
              wiadomość lub za pośrednictwem portalu – pozyskiwać leady.
              Kampanie Linkedin Ads sprawdzają się przede wszystkim dla B2B, ale
              jest to również doskonałe narzędzie dla rekrutacji czy
              poszukiwania klientów indywidualnych.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Wszystkie usługi</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Banery reklamowe</span>
            <Link to="/oferta/banery-reklamowe/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Analityka internetowa</span>
            <Link to="/oferta/analityka-internetowa/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/oferta/social-media/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/oferta/facebook-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/oferta/ads-google/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Kampanie zagraniczne</span>
            <Link to="/oferta/kampanie-zagraniczne/">zobacz</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default LinkedinAds;
